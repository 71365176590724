<template>
  <div style="height: 100%; ">
    <div
      v-if="baselineStationCollection"
      :id="container"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%'
      }"
    ></div>
  </div>
</template>

<script>
import bbox from "@turf/bbox";
import mapboxgl from "mapbox-gl";
import MapboxConfig from "@/core/plugins/mapbox";
import { mapActions, mapState } from "vuex";
import baselineMixin from "@/core/mixins/baseline.mixin";
import regionMixin from "@/core/mixins/region.mixin";

export default {
  name: "BaselineDashboardMap",
  props: {
    baselineStations: { type: Array, default: () => [] }
  },
  mixins: [baselineMixin, regionMixin],
  data: () => ({
    container: "baseline-dashboard-map",
    map: null,
    baselineStationCollection: {},
    mapStyle: {
      version: 8,
      sources: {
        eomapBasemapSource: {
          type: "raster",
          tiles: [
            `https://api.mapbox.com/styles/v1/eomap/ckq7xvq6s068c17qwcjbmc7wx/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`
          ],
          tileSize: 512,
          attribution:
            "<a href='https://www.mapbox.com/about/maps/'>© Mapbox</a> <a href='http://www.openstreetmap.org/copyright'>© OpenStreetMap</a>"
        }
      },
      layers: [
        {
          id: "basemapEomapLayer",
          type: "raster",
          source: "eomapBasemapSource",
          minzoom: 0,
          maxzoom: 22,
          layout: {
            visibility: "visible"
          }
        }
      ],
      glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf"
    }
  }),
  methods: {
    ...mapActions("baseline", ["updateSelectedStation"]),
    createMap() {
      mapboxgl.accessToken = MapboxConfig.accessToken;
      const map = new mapboxgl.Map({
        container: this.container,
        style: this.mapStyle,
        bounds: this.boundingBox
      });
      map.on("load", () => {
        this.addBaselineStationsLayer();
      });
      this.map = map;
    },
    createVSCollection() {
      const VsCollection = {
        type: "FeatureCollection",
        features: []
      };
      this.baselineStations.forEach(station => {
        VsCollection.features.push({
          type: "Feature",
          properties: {
            color: station.color,
            name: station.station_name,
            stationId: station.virtual_station_id,
            type: station.geometry.type
          },
          geometry: station.geometry
        });
      });
      return VsCollection;
    },
    addBaselineStationsLayer() {
      const map = this.map;

      if (map.getLayer("vs-source")) {
        map.removeLayer("vs-source");
      }
      if (map.getSource("vs-source")) {
        map.removeSource("vs-source");
      }
      map.addSource("vs-source", {
        type: "geojson",
        data: this.baselineStationCollection
      });
      map.addLayer({
        id: "vs-layer",
        source: "vs-source",
        type: "line",
        layout: {
          visibility: `visible`
        },
        paint: {
          "line-color": ["get", "color"],
          "line-width": 5
        },
        filter: ["==", "$type", "Polygon"]
      });
      map.addLayer({
        id: "vs-point-layer",
        source: "vs-source",
        type: "circle",
        layout: {
          visibility: `visible`
        },
        paint: {
          "circle-color": ["get", "color"],
          "circle-radius": 5
        },
        filter: ["==", "$type", "Point"]
      });
      map.addLayer({
        id: "vs-fill-layer",
        source: "vs-source",
        type: "fill",
        layout: {
          visibility: `visible`
        },
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": 0.3
        }
      });
      map.addLayer({
        id: "vs-labels",
        type: "symbol",
        source: "vs-source",
        layout: {
          "text-field": ["get", "name"],
          "icon-allow-overlap": true,
          "text-anchor": "bottom-right",
          "text-allow-overlap": true
        },
        paint: {
          "text-color": ["get", "color"]
        }
      });
      map.on("click", "vs-fill-layer", e => {
        const stationId = e.features[0].properties.stationId;
        const station = this.baselineStations.filter(
          station => station.virtual_station_id === stationId
        );
        this.updateSelectedStation(station);
        this.$router.push({
          name: "BaselineSingle",
          params: {
            stationId: stationId
          }
        });
      });

      map.on("render", () => {
        map.on("mouseenter", "vs-fill-layer", () => {
          map.getCanvas().style.cursor = "pointer";
        });
      });
    }
  },
  computed: {
    ...mapState("management", ["regions"]),
    boundingBox() {
      if (this.activeRegion) {
        return bbox(this.activeRegion);
      } else {
        return null;
      }
    },
    activeRegion() {
      return this.getActiveRegion(this.regions, this.$route.params.regionId);
    }
  },
  mounted() {
    this.baselineStationCollection = this.createVSCollection();
    this.createMap();
  }
};
</script>

<style scoped></style>
