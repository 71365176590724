<template>
  <v-main
    fluid
    class="px-5 mt-5 mb-2 d-flex flex-column flex-grow-1 overflow-y-auto overflow-x-hidden"
  >
    <v-container
      class="py-0"
      fluid
      fill-height
      v-if="baselineStations.length && productLayerSelection.length"
    >
      <v-row class="yellow" style="height: 45%">
        <v-card class="map" style="height: 100%;">
          <baseline-dashboard-map
            v-if="!loading"
            :baseline-stations="baselineStations"
          ></baseline-dashboard-map>
        </v-card>
      </v-row>
      <v-row style="height:50%;">
        <v-card class="cards" elevation="0" color="#F6F7FB">
          <baseline-product-tabs
            :region-id="activeRegionId"
            :baseline-stations-colors="baselineStations"
            :product-layer-selection="productLayerSelection"
          ></baseline-product-tabs>
        </v-card>
      </v-row>
      <v-row style="height: 50px;">
        <v-card class="ma-0" width="100%" style="background-color: #FFFFFF">
          <v-btn
            class="mt-1 ml-3"
            elevation="0"
            style="background-color: #FFFFFF"
            :disabled="selectedStations.length != 2"
            @click="toCompareBaseline"
          >
            {{ $t("baseline.compareSites") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-container>
    <v-container v-if="!loading && !baselineStations.length" fluid fill-height>
      <empty-state-vs></empty-state-vs>
    </v-container>
  </v-main>
</template>

<script>
import BaselineDashboardMap from "@/core/components/baseline/baselineDashboard/BaselineDashboardMap.vue";
import { mapActions, mapState } from "vuex";
import BaselineProductTabs from "@/core/components/baseline/baselineDashboard/BaselineProductTabs.vue";
import BaselineMixin from "@/core/mixins/baseline.mixin";
import EmptyStateVs from "@/core/components/EmptyStates/EmptyStateVS.vue";

export default {
  name: "BaselineDashboard",
  components: { EmptyStateVs, BaselineDashboardMap, BaselineProductTabs },
  mixins: [BaselineMixin],
  data: () => ({
    productLayerSelection: [],
    baselineStations: [],
    loading: true
  }),
  computed: {
    ...mapState("baseline", ["selectedStations"]),
    activeRegionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("baseline", ["setSelectedStations"]),
    toCompareBaseline() {
      this.$router.push({
        name: "BaselineCompare"
      });
    }
  },
  async created() {
    this.setSelectedStations([]);
    this.getOrFetchAccessToken().then(async () => {
      this.fetchLayers(this.activeRegionId).then(async results => {
        this.productLayerSelection = results.sort((a, b) =>
          a.title.localeCompare(b.title)
        );
        this.fetchBaselineStationsForLayer(
          this.activeRegionId,
          results[0].layerId
        ).then(result => {
          this.baselineStations = result;
          const colors = this.getColorArray();
          this.baselineStations.forEach((station, index) => {
            station.color = colors[index];
          });
          this.loading = false;
        });
      });
    });
  }
};
</script>

<style>
.map {
  height: 380px;
  width: 100%;
}

.cards {
  width: 100%;
}
</style>
