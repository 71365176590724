<template>
  <div>
    <v-row>
      <v-tabs
        style="width: 90%;"
        class="ml-3 mt-2"
        next-icon="mdi-arrow-right-drop-circle-outline"
        prev-icon="mdi-arrow-left-drop-circle-outline"
        show-arrows
        v-model="selectedProductTab"
        background-color="#F6F7FB"
      >
        <v-tab
          class="pt-2"
          v-for="(item, index) in productLayerSelection"
          :key="item.layerId"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <p v-bind="attrs" v-on="on" v-if="selectedProductTab == index">
                {{ item.product }} - {{ item.title }}
              </p>
              <p v-bind="attrs" v-on="on" v-else>
                {{ item.product }}
                <span v-if="item.title.match(/\((.*?)\)/)">
                  {{ item.title.match(/\((.*?)\)/)[0] }}</span
                >
              </p>
            </template>
            {{ item.title }}
          </v-tooltip>
        </v-tab>
      </v-tabs>
      <v-spacer />
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            right
            v-bind="attrs"
            v-on="on"
            class="mr-8 mt-3"
            icon
            @click="toggleSearch"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        {{ $t("baseline.searchStation") }}
      </v-tooltip>
    </v-row>
    <v-expansion-panels style="elevation: 0" v-model="search" multiple>
      <v-expansion-panel style="elevation: 0">
        <v-expansion-panel-content color="#F6F7FB" style="elevation: 0">
          <v-text-field
            class="mx-4 ml-16"
            v-model="searchStation"
            :label="$t('baseline.searchStation')"
            clearable
            hide-details
            persistent-hint
          ></v-text-field>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-tabs-items
      v-model="selectedProductTab"
      style=" background-color:#F6F7FB"
    >
      <v-tab-item
        v-for="item in productLayerSelection"
        :key="item.layerId"
        style="background-color: #F6F7FB"
      >
        <v-container
          v-if="baselineStations.length"
          style="background-color: #F6F7FB"
          class="fill-height overflow-y-auto ma-0 pb-10 align-content-start "
          fluid
        >
          <v-card
            v-for="station in filteredBaselineStations"
            :key="station.id"
            elevation="0"
            width="100%"
            class="my-1"
            outlined
            :style="{ 'border-left-color': station.color }"
            style="border-left-width: thick"
          >
            <v-card-text
              v-if="station.overall_statistics.min"
              class="pt-1 pb-4"
            >
              <v-row>
                <v-col cols="auto">
                  <v-checkbox
                    :disabled="baselineStations.length < 2"
                    v-model="station.check"
                    class="station-labels"
                    @click="editSelectedStations(station)"
                    label=""
                    hide-details
                  ></v-checkbox>
                </v-col>
                <v-col cols="3" class="pt-7 pb-0">
                  <v-hover v-slot="{ hover }">
                    <p
                      class="row-pointer"
                      @click="toBaselineSingle(station)"
                      :title="$t('monitoring.toDetailsPage')"
                      :class="
                        hover ? 'text-h6 text-decoration-underline' : 'text-h6'
                      "
                    >
                      {{ station.station_name }}
                    </p>
                  </v-hover>
                </v-col>
                <v-col
                  >{{ $t("baseline.min") }}
                  <p
                    v-if="station.overall_statistics.min"
                    :style="{
                      color: getStatusColor(
                        station.overall_statistics.min,
                        productThreshold
                      )
                    }"
                    class="my-0 text-h4"
                  >
                    {{ station.overall_statistics.min.toFixed(2) }}
                  </p></v-col
                >
                <v-divider class="mt-4" vertical />
                <v-col
                  >{{ $t("baseline.max") }}
                  <p
                    v-if="station.overall_statistics.max"
                    :style="{
                      color: getStatusColor(
                        station.overall_statistics.max,
                        productThreshold
                      )
                    }"
                    class="my-0 text-h4"
                  >
                    {{ station.overall_statistics.max.toFixed(2) }}
                  </p></v-col
                >
                <v-divider class="mt-4" vertical />
                <v-col
                  >{{ $t("baseline.median") }}
                  <p
                    v-if="station.overall_statistics.median"
                    :style="{
                      color: getStatusColor(
                        station.overall_statistics.median,
                        productThreshold
                      )
                    }"
                    class="my-0 text-h4"
                  >
                    {{ station.overall_statistics.median.toFixed(2) }}
                  </p></v-col
                >
                <v-divider class="mt-4" vertical />
                <v-col
                  >{{ $t("baseline.standardDeviation") }}
                  <p class="my-0 text-h4">
                    {{ station.overall_statistics.stddev.toFixed(2) }}
                  </p></v-col
                >
                <v-divider class="mt-4" vertical />
                <v-col>
                  {{ $t("baseline.numberOfScenes") }}
                  <p class="my-0 text-h4">
                    {{ station.overall_statistics.nb_scenes.toFixed(0) }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
        <v-container v-else class="fill-height overflow-y-auto mx-0" fluid>
          <v-overlay opacity="0.2" absolute>
            <div>
              <v-progress-circular
                indeterminate
                color="primary"
                size="64"
              ></v-progress-circular>
            </div>
          </v-overlay>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import baselineMixin from "@/core/mixins/baseline.mixin";

export default {
  name: "BaselineProductTabs",
  props: {
    baselineStationsColors: { type: Array, default: () => [] },
    productLayerSelection: { type: Array, default: () => [] },
    regionId: null
  },
  mixins: [baselineMixin],
  data: () => ({
    loading: true,
    selectedProductTab: 0,
    selectedProduct: null,
    tempSelectedStations: [],
    baselineStations: [],
    filteredBaselineStations: [],
    regionThresholds: [],
    productThreshold: {},
    searchStation: null,
    search: []
  }),
  computed: {
    ...mapState("baseline", ["selectedStations"])
  },
  methods: {
    ...mapActions("app", ["showSnackbar"]),
    ...mapGetters("baseline", ["getStations"]),
    ...mapActions("baseline", [
      "updateSelectedStations",
      "setSelectedStations",
      "updateSelectedStation",
      "updateProduct"
    ]),
    editSelectedStations(station) {
      this.updateSelectedStations(station);
      if (this.selectedStations.length >= 3) {
        this.showSnackbar({
          show: true,
          message: this.$t("baseline.onlyTwoMessage"),
          color: "error"
        });
      }
    },
    toggleSearch() {
      if (this.search.length > 0) {
        this.search = [];
      } else {
        this.search = [0];
      }
    },
    toBaselineSingle(station) {
      this.updateSelectedStation(station);
      this.$router.push({
        name: "BaselineSingle",
        params: {
          stationId: station.virtual_station_id
        }
      });
    },
    defineProductThresholds() {
      const thresholds = this.regionThresholds.filter(
        productThreshold =>
          productThreshold.product === this.selectedProduct.product
      );
      if (thresholds.length) {
        return thresholds[0];
      } else {
        return null;
      }
    },
    async fetchStationRequest() {
      if (this.baselineStations.length == 0) {
        this.fetchBaselineStationsForLayer(
          this.regionId,
          this.selectedProduct.layerId,
          true
        ).then(results => {
          this.baselineStations = results;
          this.baselineStations.forEach((station, index) => {
            station.check = false;
            station.color = this.baselineStationsColors[index].color;
          });
          this.filteredBaselineStations = this.baselineStations;
          this.sortName();
        });
      } else {
        clearInterval(this.intervalId);
      }
    },
    sortName() {
      this.filteredBaselineStations.sort((a, b) =>
        a.station_name.toUpperCase().localeCompare(b.station_name.toUpperCase())
      );
    }
  },
  watch: {
    async selectedProductTab() {
      this.loading = true;
      this.setSelectedStations([]);
      this.baselineStations = [];
      this.selectedProduct = this.productLayerSelection[
        this.selectedProductTab
      ];
      this.updateProduct(this.selectedProduct);
      await this.fetchStationRequest();
      this.loading = false;
      this.productThreshold = this.defineProductThresholds();
    },
    tempSelectedStations: {
      handler(value) {
        this.$emit("selectedStations", value);
      }
    },
    searchStation() {
      this.baselineStations.forEach(baseStation => {
        const exists = this.filteredBaselineStations.filter(
          station => station.id === baseStation.id
        );
        if (!exists.length) {
          this.filteredBaselineStations.push(baseStation);
        }
      });
      if (this.filteredBaselineStations.length) {
        this.sortName();
        this.filteredBaselineStations = this.filteredBaselineStations.filter(
          station =>
            station.station_name
              .toLowerCase()
              .includes(this.searchStation.toLowerCase())
        ); //to lowercase to make in case insensitive
      }
    }
  },
  async created() {
    this.selectedProduct = this.productLayerSelection[this.selectedProductTab];
    this.updateProduct(this.selectedProduct);
    await this.fetchStationRequest();
    this.regionThresholds = await this.fetchRegionThresholds(this.regionId);
    this.productThreshold = this.defineProductThresholds();
  }
};
</script>

<style scoped>
::v-deep .station-labels .v-label {
  font-size: 18px;
}

.container.fill-height {
  height: 42vh !important;
}

.v-expansion-panel:before {
  box-shadow: none !important;
}

.row-pointer {
  cursor: pointer;
}
</style>
